@import '../styles/variables.scss';

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  
    
  
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    
      
  
      @media (min-width: $breakpoint-desktop) { // lg breakpoint
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--background-color);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(15px);
      }
    }
  }