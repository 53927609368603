@import './variables.scss';


body {
  font-family: var(--font-family-body);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  font-weight: var(--font-weight-normal);
  transition: background-color 0.3s ease-out, color 0.3s ease-out;
  padding-top: 10%;
  // padding-left: 5%;
  padding-right: 5%;
  margin-top: 10%;
  

  &.light {
    color: var(--font-color-dark);
    background-color: var(--font-color-light);
  }

  &.dark {
    color: var(--font-color-light);
    background-color: var(--font-color-dark);
  }
}


a {
  color: rgb(0, 176, 203); 
  text-decoration: none;

  &:hover {
    color: rgb(178, 175, 130); 
  }
}


@media (min-width: $breakpoint-tablet) {
  body {
    margin-left: 5%;
    margin-top: 5%;
  }
}

@media (min-width: $breakpoint-desktop) {
  body {
    margin-left: 5%;
    margin-top: 0;
    padding-top: 5%;
    margin-right: 5%;
  }
}