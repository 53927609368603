@import '../styles/variables.scss';

.gallery {
  display: flex;
  align-items: center;
  justify-content: center;
 
  
  ::-webkit-scrollbar {
    display: none; 
  }
  
  .gallery-item {
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    margin-right: 100px;
  
    
    
    .gallery-image {
      max-width: 90%; // Ensure it's responsive and doesn't overflow its container
      height: 400px; // Set a maximum height
      object-fit: contain; // Keep the aspect ratio of the image
      align-items: center;
    }
    
    .gallery-item-info {
      // Additional styles here
      text-align: center; // Center the text
      align-items: center;
     
      padding-right: 5%;
      max-width: 90%;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .gallery-image {
    min-height: 60vh;

  }
}