@import '../styles/variables.scss';

// The desktop navigation styles
.nav-desktop {
  display: none; // By default, it's hidden like the 'hidden' class

  @media (min-width: $breakpoint-desktop) {
    display: flex; // Equivalent to 'lg:flex'
    align-items: center;
    justify-content: center;
    gap: 1.25rem; // Equivalent to 'gap-5'
    width: 100%;
  }

  // Font size similar to 'text-sm'
  font-size: var(--font-size-base);
}