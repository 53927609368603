@import '../styles/variables.scss';

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 5%;
    margin-left: 5%;
    background-color: var(--background-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    h2 {
      margin-bottom: 20px;
      color: var(--font-color);
      font-family: var(--font-family-body);
    }
  
    label {
      align-self: flex-start;
      margin-bottom: 5px;
      color: var(--font-color);
      font-weight: var(--font-weight-normal);
    }
  
    input[type="text"],
    input[type="email"],
    textarea {
      width: 100%;
      padding: 10px;
      margin-bottom: 15px;
      border: 1px solid #ccc;
      border-radius: 5px;
      font-size: var(--font-size-base);
      color: var(--font-color);
      background-color: var(--input-background-color);
      font-family: var(--font-family-body);
  
      &:focus {
        outline: none;
        border-color: rgb(0, 176, 203);
      }
    }
  
    button {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      background-color: rgb(0, 176, 203);
      color: #FAFAFA;
      font-size: var(--font-size-base);
      cursor: pointer;
      transition: background-color 0.3s ease;
      font-weight: bold;
      
  
      &:hover {
        background-color: rgb(178, 175, 130);
      }
    }
  }
  
  .success-message {
    text-align: center;
    color: var(--font-color);
    font-size: var(--font-size-base);
  }