@import '../styles/variables.scss';



.nav-mobile {
  display: block; 
  justify-content: flex-end; 
  align-items: center; 
  position: fixed;
  background-color: var(--background-color);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(15px);
  top: 0; 
  padding-left: 5%;
  padding-top: 2%;
  width: 100%;  
  z-index: 1000; 
  

  @media (min-width: $breakpoint-desktop) {
    display: none;
  }
}

.menu-overlay {
  position: relative;
  left: 0;
  right: 0;
  background: transparent;
  padding-left: 3%;
  height: 100vh;
  z-index: 1000; 
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: fit-content;

  li {
    padding: 0.5rem 0; 

    a {
      text-decoration: none;
      color: var(--font-color); 
      display: block;

      &:hover {
        color: rgb(178, 175, 130);
      }
    }
  }
}